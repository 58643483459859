import React, {Component} from "react";
import {Link} from "react-router-dom";
import user_icon from "./images/Icon_feather-user.svg";

class Welcome extends Component {

    setBacklink() {
        const {dataBacklink} = this.props;
        dataBacklink.link = '/Logout';
    }

    handleWelcome() {
        let countdown;

        function goOrganisationTimeout() {
            document.getElementById('header').style.display = "block";
            document.getElementById('welcome_screen').click();
        }

        function goOrganisation() {
            document.getElementById('header').style.display = "block";
            clearTimeout(countdown);
        }

        function setCountdown(){
            countdown = setTimeout(function(){goOrganisationTimeout();}, 5000);
        }
        setCountdown();

        document.getElementById('welcome_screen').addEventListener('click', goOrganisation);
    };

    fetchOrganisations() {
        const {dataOrganisation} = this.props;

        fetch('/groups.json')
            .then(response => response.json())
            .then(data => {
                for (var i = 0; i < data.groups.length; i++) {
                    dataOrganisation[i] = data.groups[i];
                }
            });
    }

    componentDidMount() {
        if (document.getElementById('header')) {
            document.getElementById('header').style.display = 'none';
        }
    }

    componentWillUnmount() {
        this.setBacklink();
    }

    render() {

        const {dataUsername} = this.props;

        return(
                <Link to="/Organisation" onLoad={this.handleWelcome}>
                    {this.fetchOrganisations()}
                    <main id="welcome_screen">

                        <div className="content">
                            <img src={user_icon} alt="user" />
                            <h1>Willkommen,<br/>{dataUsername}!</h1>
                        </div>
                    </main>
                </Link>
        )
    }
}

export default Welcome