import React, {Component} from "react";
import {Link} from "react-router-dom";
import logout_icon from "./images/Icon_feather-log-out.svg";
import check_icon from "./images/sent-check.svg";

class Success extends Component {

    checkStatus() {

        const {dataList} = this.props;

        for (var i = 0; i < dataList.length; i++) {
            let stat = dataList[i].status;
            let pre = dataList[i].notavailable;

            if (stat === 2 && pre === false) {
                return (
                    <div>
                        <h2>Einige Fahrgäste fehlen unerwartet.</h2>
                        <p>
                            Das Sekretatriat wird die Liste untersuchen und die fehlenden Fahrgäste kontaktieren.
                        </p>
                    </div>
                )
            }
        }
    }

    componentDidMount() {
        if (document.getElementById('header')) {
            document.getElementById('header').style.display = 'block';
        }
    }


    render() {

        return (
            <main id="success_screen">
                <h1>Vielen Dank!</h1>
                {this.checkStatus()}
                <div className="hide">
                    <h2>Einige Fahrgäste fehlen unerwartet.</h2>
                    <p>
                        Das Sekretatriat wird die Liste untersuchen und die fehlenden Fahrgäste kontaktieren.
                    </p>
                </div>
                <Link to={"/Logout"}>
                    <img src={logout_icon} alt="Logout"/>
                </Link>
            </main>
        );


    }

}

export default Success