import React, {Component} from "react";
import {Link} from "react-router-dom";

class Logout extends Component {

    componentDidMount() {
        if (document.getElementById('header')) {
            document.getElementById('header').style.display = 'none';
        }
    }

    render() {

        const {handleStayCallback} = this.props;

        return (
            <main id="logout_screen">
                <h1>Möchten Sie sich ausloggen?</h1>
                <div>
                    <div className="content">
                        <Link to={'/Login'}>
                            <button className="button">Ja</button>
                        </Link>
                        <Link to={'/Organisation'} onClick={handleStayCallback}>
                            <button className="button">Nein</button>
                        </Link>
                    </div>
                </div>
            </main>
        )
    }
}

export default Logout