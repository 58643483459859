import React, {Component} from "react";
//import Header from "./Header";
import Login from "./Login";
import Welcome from "./Welcome";
import Organisation from "./Organisation";
import Line from "./Line";
import List from "./List";
import Success from "./Success";
import Logout from "./Logout";

import {
    BrowserRouter as Router,
    Switch,
    Link,
    Route
} from "react-router-dom";

import back_icon from "./images/back-arrow.svg";
import check_icon from "./images/sent-check.svg";





class App extends Component {

    constructor(props) {
        super(props);

        this.initialState = {
            username:'',
            password:'',
            organisations: [],
            currentOrganisation: {title: '', url: '', id: ''},
            lines:[],
            currentLine: {id: '', title: ''},
            currentLineStatus: '',
            list: [],
            backlink: {link:'/Logout'}
        };

        this.state = this.initialState;
    }

    handleChange = event => {
        const {id, value} = event.target;

        this.setState({
            [id]: value
        });
    };

    handleSubmit = event => {
        this.setState({password: ''})
    };

    handleOrganisation = event => {
        let orga = event.target.getAttribute('src');
        let name = event.target.getAttribute('alt');
        let uid = event.target.getAttribute('data-uid');

        this.setState(
            {
                currentOrganisation : {title: name, url: orga, id: uid},
                backlink: {link: '/Organisation'}
            }
        );

        fetch('/courses_' + uid + '.json')
            .then(response => response.json())
            .then(data => {
                this.setState(
                    {
                        lines: data.courses
                    }

                );
            })
    };

    handleLineStatus = event => {
        const {id, value} = event.target;
        let status = event.target.dataset['line_status'];

        this.setState({
            [id]: value,
            currentLineStatus: status
        });
    };

    handleLine = event => {

        let line = event.target.dataset['uid'];
        let name = event.target.dataset['title'];

        this.setState(
            {
                currentLine : {id: line, title: name},
                backlink: {link: "/Line"}
            }
        );

        fetch('/courseList_' + line + '.json')
            .then(response => response.json())
            .then(data => {
                this.setState(
                    {
                        list: data.list
                    }

                );
            });

    };

    handleListSubmit = event => {
        //event.preventDefault();
        console.log([this.state.currentOrganisation.title, this.state.currentLine.title, this.state.currentLineStatus, this.state.list ]);

        const response = fetch('/submit', {
            method: 'POST', // *GET, POST, PUT, DELETE, etc.
            mode: 'cors', // no-cors, *cors, same-origin
            cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
            credentials: 'same-origin', // include, *same-origin, omit
            headers: {
                'Content-Type': 'application/json'
                // 'Content-Type': 'application/x-www-form-urlencoded',
            },
            redirect: 'follow', // manual, *follow, error
            referrerPolicy: 'no-referrer', // no-referrer, *no-referrer-when-downgrade, origin, origin-when-cross-origin, same-origin, strict-origin, strict-origin-when-cross-origin, unsafe-url
            body: JSON.stringify([this.state.currentOrganisation.title, this.state.currentLine.title, this.state.currentLineStatus, this.state.list]) // body data type must match "Content-Type" header
        });


        this.setState({backlink: {link:"/Logout"}});
        document.getElementById('header').querySelector('img').setAttribute('src',check_icon);
    };

    handleStay = event => {
        this.setState({currentLine: {id: '', title: ''}, currentOrganisation: {title: '', url: '', id: ''}})
    };



    handleBacklink = event => {
        if (this.state.backlink.link === "/Organisation") {
            this.setState({backlink: {link: "/Logout"}, currentOrganisation: {title: '', url: '', id: ''}})
        }
        else if (this.state.backlink.link ==="/Line") {
            this.setState({backlink: {link: "/Organisation"}, currentLine: {id: '', title: ''}});
        }
        else if (this.state.backlink.link ==="/List") {
            this.setState({backlink: {link: "/Line"}});
        }

    };



    render() {

        const {username, organisations, currentOrganisation, lines, currentLine, list, backlink} = this.state;

        return (
            <Router>

                <header id="header">
                    <Link to={this.state.backlink.link} onClick={this.handleBacklink}>
                        <img src={back_icon} alt="&#10132;"/>
                    </Link>
                    <div>
                        <p>{this.state.username}</p>
                        <img src={this.state.currentOrganisation.url} alt={this.state.currentOrganisation.title} />
                        <p>{this.state.currentLine.title}</p>
                    </div>
                </header>



                <Switch>

                    <Route path="/Welcome">
                        <Welcome
                            dataUsername={username}
                            dataOrganisation={organisations}
                            dataBacklink={backlink}/>
                    </Route>

                    <Route path="/Organisation">
                        <Organisation
                            dataOrganisation={organisations}
                            handleOrganisationCallback={this.handleOrganisation}/>
                    </Route>

                    <Route path="/Line">
                        <Line
                            dataLines={lines}
                            handleLineCallback={this.handleLine}
                            handleLineStatusCallback={this.handleLineStatus}/>
                    </Route>

                    <Route path="/List">
                        <List
                            dataList={list}
                            dataBacklink={backlink}
                            handleListSubmitCallback={this.handleListSubmit}/>
                    </Route>

                    <Route path="/Success">
                        <Success
                            dataList={list}
                            dataBacklink={backlink}/>
                    </Route>

                    <Route path="/Logout">
                        <Logout
                            dataCurrentOrganisation={currentOrganisation}
                            dataCurrentLine={currentLine}
                            handleStayCallback={this.handleStay}/>
                    </Route>

                    <Route path="/">
                        <Login
                            handleChangeCallback={this.handleChange}
                            handleSubmitCallback={this.handleSubmit}/>
                    </Route>

                </Switch>

            </Router>
        )
    }
}

export default App