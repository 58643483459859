import React, {Component} from "react";
import {Link} from "react-router-dom";
import check_icon from "./images/sent-check.svg";
import list_cross from "./images/Icon_feather-cross.svg";
import list_check from "./images/Icon_feather-check.svg";

class List extends Component {

    handleListChange = event => {

        const {name, value} = event.target;
        const {dataList} = this.props;

        for (var j = 0; j < dataList.length; j++) {

            let inp = document.getElementById('passagierliste').querySelectorAll('input:checked[name="customer' + dataList[j].customerID + '"]');

            if (inp.length === 1) {

                if (dataList[j].notavailable === true && inp[0].value === "true") {
                    dataList[j].status = "eingestiegen trotz Entschuldigung";
                }
                else if (dataList[j].notavailable === true && inp[0].value === "false") {
                    dataList[j].status = "entschuldigt";
                }
                else if (dataList[j].notavailable === false && inp[0].value === "true") {
                    dataList[j].status = "eingestiegen";
                }
                else if (dataList[j].notavailable === false && inp[0].value === "false") {
                    dataList[j].status = "fehlt unerwartet";
                }
            }
        }

        this.setState({
            [name]: value
        });


        //Enable/Disable Passenger Button

        var options = (document.getElementById('passagierliste').querySelectorAll('input[type="radio"]').length) / 2;
        var counter = 0;

        //determine and count checked pairs

        for (var i = 0; i < options;) {
            i++;
            var option_true = document.getElementById('passagierliste').querySelector('input[data-uid="' + (i - 1) + '_true"]');
            var option_false = document.getElementById('passagierliste').querySelector('input[data-uid="' + (i - 1) + '_false"]');

            if (option_true.checked || option_false.checked) {
                counter++;
            }
        }

        //if checked pairs are equal to check options -> enable button

        if (counter === options) {
            document.getElementById('passagierbutton').removeAttribute('disabled');
        } else {
            document.getElementById('passagierbutton').setAttribute('disabled', 'disabled');
        }

    };

    markUnselected = event => {

        var options = (document.getElementById('passagierliste').querySelectorAll('input[type="radio"]').length) / 2;

        for (var i = 0; i < options;) {
            i++;
            var option_true = document.getElementById('passagierliste').querySelector('input[data-uid="' + (i - 1) + '_true"]');
            var option_false = document.getElementById('passagierliste').querySelector('input[data-uid="' + (i - 1) + '_false"]');
            var passenger_line = document.getElementById('passagierliste').querySelector('p:nth-of-type(' + i + ')');

            if (!option_true.checked && !option_false.checked) {
                passenger_line.style.color = 'red';
            }
        }
    };

    componentDidUpdate(prevProps, prevState, snapshot) {
        this.preselectBoxes(prevProps);
    }

    preselectBoxes() {

        const {dataList} = this.props;
        let inputs = document.getElementById('passagierliste').querySelectorAll('input[value=false');

        for (var i = 0; i < inputs.length; i++) {
            if (dataList[i].notavailable === true) {
                inputs[i].setAttribute('checked', "true");
            }
        }
    }

    componentDidMount() {

        if (document.getElementById('header')) {
            document.getElementById('header').style.display = 'block';
        }
    }

    render() {

        const {dataList, handleListSubmitCallback} = this.props;

        const passengers = dataList.map((passenger, index) =>
            <React.Fragment key={index}>
                <p data-preselect={passenger.notavailable}>
                    {index + 1 + '. '} {passenger.name} <span className="classnumber"> (Klasse {passenger.classnumber})</span>
                    <span>{passenger.comments}</span>
                </p>
                <label>
                    <input
                        type="radio"
                        name={'customer' + passenger.customerID}
                        value="true"
                        data-uid={index + '_true'}
                        onChange={this.handleListChange}/>
                    <span><img className="list_check" src={list_check} alt="&#10003;"/></span>
                </label>
                <label>
                    <input
                        type="radio"
                        name={'customer' + passenger.customerID}
                        value="false"
                        data-preselect={passenger.notavailable}
                        data-uid={index + '_false'}
                        onChange={this.handleListChange}/>
                    <span><img className="list_cross" src={list_cross} alt="&#10005;"/></span>
                </label>
            </React.Fragment>
        );

        return (
            <main id="passenger_screen">
                <div className="passenger_content">
                    <h2>Überprüfen Sie, ob die Schüler eingestiegen sind.</h2>
                    <p>Wenn Sie am Ziel angelangt sind und alle Schüler eingetragen haben, können Sie die Liste
                        abschicken.</p>

                    <form
                        method="post"
                        id="passagierliste">

                        <div className="list">
                            <h3>Name des Schülers</h3>
                            <h3>Ein&shy;ge&shy;stie&shy;gen</h3>
                            <h3>Nicht ein&shy;ge&shy;stie&shy;gen</h3>

                            {passengers}

                        </div>

                        <div className="list_submit" onClick={this.markUnselected}>
                            <Link to="/Success" onClick={handleListSubmitCallback}>
                                <input
                                    type="submit"
                                    value="Liste abschicken"
                                    className="button"
                                    id="passagierbutton"
                                    disabled="disabled"
                                />
                            </Link>
                        </div>

                    </form>
                </div>
            </main>

        );
    }
}

export default List
