import React, {Component} from "react";
import {Link} from "react-router-dom";
import back_icon from "./images/back-arrow.svg";

class Organisation extends Component {

    componentDidMount() {

        if (document.getElementById('header')) {
            document.getElementById('header').style.display = 'block';
        }
        document.getElementById('header').querySelector('img').setAttribute('src',back_icon);
    }

    render() {

        const {dataOrganisation, handleOrganisationCallback} = this.props;

        const organisations = dataOrganisation.map((organisation, index) =>
            <Link to="/Line" key={index}>
                <img src={organisation.url} alt={organisation.title} data-uid={organisation.id} onClick={handleOrganisationCallback} />
            </Link>
        );

        return (

            <main id="organisation_screen">
                <h2>Wählen Sie Ihre Organisation aus.</h2>
                <div className="content">
                    {organisations}
                </div>
            </main>
        )
    }
}

export default Organisation