import React, {Component} from "react";
import {Link} from "react-router-dom";
import back_icon from "./images/back-arrow.svg";
import list_cross from "./images/Icon_feather-cross.svg";
import list_check from "./images/Icon_feather-check.svg";

class Line extends Component {

    componentDidMount() {

        if (document.getElementById('header')) {
            document.getElementById('header').style.display = 'block';
        }
        document.getElementById('header').querySelector('img').setAttribute('src',back_icon);
    }

    render() {

        const {dataLines, handleLineCallback, handleLineStatusCallback} = this.props;

        const lines = dataLines.map((line, index) =>
            <React.Fragment key={index}>
                <Link to="/List">
                    <button
                        className="line_button"
                        onClick={handleLineCallback}
                        data-uid={line.id}
                        data-title={line.title}>
                            {line.title}
                    </button>
                </Link>
                <p>{line.course}</p>
            </React.Fragment>
        );

        return (

            <main id="line_screen">
                <div className="line_content">
                    <h2>Wählen Sie Ihre aktuelle Buslinie aus.</h2>
                    <p>Die aktuellen Linien sowie die folgende Checkliste richten sich nach dem aktuellen Datum.</p>
                    <div className="line_switch">
                        <div>
                            <p>Hinfahrt</p>
                            <label>
                                <input  type="radio"
                                        name="line_switch"
                                        data-line_status="Hinfahrt"
                                        onChange={handleLineStatusCallback}
                                />
                                <span><img className="list_check" src={list_check} alt="&#10005;"/></span>
                            </label>
                        </div>
                        <div>
                            <p>Rückfahrt</p>
                            <label>
                                <input  type="radio"
                                        name="line_switch"
                                        data-line_status="Rückfahrt"
                                        onChange={handleLineStatusCallback}
                                />
                                <span><img className="list_check" src={list_check} alt="&#10005;"/></span>
                            </label>
                        </div>

                    </div>
                    {lines}

                </div>
            </main>
        )
    }
}

export default Line