import React, {Component} from "react";
import {Link} from "react-router-dom";

class Login extends Component {

    activateButton() {

        let username = document.getElementById('username');
        let password = document.getElementById('password');

        if ( username.value === 'jowieadmin' && password.value === 'hShaYB9ZYC6eHbyv' ) {
            document.getElementById('loginbutton').removeAttribute('disabled');
        }
        else {
            document.getElementById('loginbutton').setAttribute('disabled', 'disabled');
        }
    }

    componentDidMount() {
        if (document.getElementById('header')) {
            document.getElementById('header').style.display = 'none';
        }
    }

    render(username, password) {

        const {handleChangeCallback, handleSubmitCallback} = this.props;

        return (
            <main id="login_screen">
                <h1>Bitte loggen Sie sich ein.</h1>
                <div>
                    <div className="content">
                        <form
                            method="post"
                            id="login">

                            <label htmlFor="username">Ihr Name</label>
                            <input
                                type="text"
                                id="username"
                                name="name"
                                placeholder="Vorname Nachname"
                                value={username}
                                onChange={handleChangeCallback}
                                onKeyUp={this.activateButton} />
                            <label htmlFor="password">Ihr Passwort</label>
                            <input
                                type="password"
                                id="password"
                                name="pass"
                                value={password}
                                onChange={handleChangeCallback}
                                onKeyUp={this.activateButton} />
                            <Link to="/Welcome" onClick={handleSubmitCallback}>
                                <input
                                    type="submit"
                                    value="Bestätigen"
                                    className="button"
                                    id="loginbutton"
                                    disabled="disabled"/>
                            </Link>
                        </form>
                    </div>
                </div>
            </main>
        );
    }
}

export default Login